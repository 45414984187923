<script>
export default {
	name: 'Pay',

};
</script>

<template>
	<div class="container">
		<div class="pay-box">
			<div class="pay-left">
				<h2 class="title fs-56 mb-60">Оплата</h2>
				<div class="pay-item text-medium fs-20">
					<img src="../assets/images/hand.svg" alt="logo">
					<div>Стоимость месячной подписки составляет <span class="orange"> 499 рублей</span></div>
				</div>
				<div class="pay-item text-medium fs-20">
					<img src="../assets/images/calendar.svg" alt="logo">
					<div>После оплаты вы получаете <span class="orange">доступ на 1 месяц</span></div>
				</div>

				<div class="mb-24">
					<div style="font-size: 16px; font-weight: 700; margin-bottom: 10px;">Ваш UID из приложения</div>
					<input style="margin-bottom: 10px;" type="tel" id="uid" name="uid" placeholder="Введите UID" />

					<input type="checkbox" class="custom-checkbox" id="happy" name="happy" value="yes">
					<label style="font-size: 20px; margin-bottom: 10px;" for="happy">Даю своё согласие на то, что платёж
						будет автоматически списываться раз в месяц в размере стоимости тарифа</label>
					<button class="button orange-bg fs-20 orange-hover">Оплатить</button>
				</div>

				<div class="fs-20 mb-24">
					Оплачивая подписку, вы соглашаетесь с 
					<a href="https://docs.google.com/document/d/1zLZJPgDBnrqfw0n13xBL6z7wLn_2w55qLLY4DuiNdwo/edit" class="text-violet">правилами отмены и возврата</a>,
					с <a href="https://myvpn.tech/docs/offer.pdf" class="text-violet">офертой</a> и с 
					
					<a class="text-violet" 
						target="_blank" 
						href="https://drive.google.com/file/d/1Sc7JtKGe2ykGfwFrhkLLaCwQYt29cTuC/view">
					политикой обработки	персональных данных.
					</a>
				</div>

				<div class="fs-20">
					Ознакомьтесь с информацией о <a href="https://docs.google.com/document/d/1UvwRtBFGid8ntaX_hb-lbt0cermQjXRc9ZIqiQCtI2A/edit" class="text-violet">тарифах и правилах рекуррентных
						платежей</a> (стоимость, периодичность списаний, возможность отмены подписки, возврат
					средств, реквизиты для обращения)
				</div>
			</div>

			<div class="pay-right">
				<img style="max-width: 400px;" src="../assets/images/main.png" alt="">

				<div style="display: flex; align-items: flex-start; gap: 16px;">
					<img style="margin-top: 10px;" src="../assets/images/ellipse.svg" alt="">
					<div class="fs-20">Вы в любой момент можете отключить эту функцию через поддержку</div>
				</div>
				<div style="display: flex; align-items: flex-start; gap: 16px;">
					<img style="margin-top: 10px;" src="../assets/images/ellipse.svg" alt="">
					<div class="fs-20">В случае возникновения проблем обратитесь в поддержку</div>
				</div>

				<button class="button orange-hover header-button">
					<a class="button-link" target="_blank" href="https://t.me/myvpn_chat">
						<div class="fs-20" style="font-weight: 700;">Чат с поддержкой</div>
					</a>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
input {
	background-color: transparent;
	border: 1px solid #303030;
	border-radius: 12px;
	height: 55px;
	padding: 12px;
	width: 100%;
}

.pay-box{
	margin-bottom: 20px;
}

input::placeholder {
	font-size: 20px;
	color: #A6ADB8;
}

button {
	height: 54px;
	width: 100%;
	text-align: center;
}

.pay-item img {
	width: 34px;
	height: 34px;
}
</style>