<template>
  <div class="main">
      <a target="_blank"
    href="https://play.google.com/store/apps/details?id=ai.myvpn.app"><img
    src="./assets/images/googleplay.png" alt="Google"></a>
  </div>
</template>

<script>

export default {
  name: 'Android',
  mounted() {
    console.log('Android component mounted.')
  }
}

</script>

<style scoped>
.main {
display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    }
</style>

