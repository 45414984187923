<template>
  <main>
    <section id="main">
      <MainComponent />
    </section>
    <section id="why">
      <Why />
    </section>
    <section id="locations">
      <Location />
    </section>
    <section id="tariff">
      <Tariff />
    </section>
    <section id="questions">
      <Questions />
    </section>
  </main>
</template>

<script>
import HeaderComponent from '@/components/common/HeaderComponent.vue';
import MainComponent from '@/components/MainComponent.vue';
import Why from '@/components/Why.vue';
import Location from '@/components/Location.vue';
import Tariff from '@/components/Tariff.vue';
import Questions from '@/components/Questions.vue';
import Pay from '@/components/Pay.vue';
import FooterComponent from '@/components/common/FooterComponent.vue';

export default {
  name: 'MainLayout',
  components: {
    HeaderComponent,
    MainComponent,
    Why,
    Location,
    Tariff,
    Questions,
    Pay,
    FooterComponent,
  },
};
</script>
