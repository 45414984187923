<script>
export default {
    name: 'Questions',

};
</script>

<template>
    <div class="container">
        <div class="quest-container">
            <h2 class="title fs-56 text-center mb-24">Как пользоваться</h2>

            <div class="title fs-24 text-center mb-60">Скачайте приложение и используйте My<span class="orange">VPN</span> с удовольствием</div>

            <div class="mb-60" style="display: flex; justify-content: center; gap: 40px;">
                <button class="button blue-hover" style="flex: 1; border: 2px solid #5F5FC2;"><img src="../assets/images/apple-black.svg" alt=""></button>

                <button class="button orange-hover" style="flex: 1; border: 2px solid #FAA820;"><img src="../assets/images/android-black.svg" alt=""></button>
            </div>
        </div>

        <h2 class="title fs-56 text-center mb-60">Вопросы о My<span class="orange">VPN</span></h2>
        <div class="quest-container">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item br-24 mb-12">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button br-24" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Как я могу поменять тариф?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show br-bottom" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body fs-20">
                            На всех тарифах MyVPN трафик неограничен.
                        </div>
                    </div>
                </div>
                <div class="accordion-item br-24 mb-12">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed br-24" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Я могу установить ваш ВПН и на ноутбук, и на смартфон?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse br-bottom" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body fs-20">
                            Да, вы можете установить и пользоваться MyVPN на ПК, ноутбук и мобильный телефон на Android и IOS.
                        </div>
                    </div>
                </div>
                <div class="accordion-item br-24">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed br-24" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Стоимость не зависит от объёма используемого трафика?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse br-bottom" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body fs-20">
                            Стоимость не зависит от объёма используемого трафика.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>