import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import "./assets/styles/reset.css";
import "./assets/styles/fonts.css";
import "./assets/styles/vars.css";
import "./assets/styles/style.css";
import "./assets/styles/media.css";
import App from "./App.vue";
import Android from "./Android.vue";
import router from "./router";

import { createStore } from 'vuex'

const store = createStore({
  state: {
    utm: {},
    bot_url: 'https://t.me/MyVPN_ai_bot'
  },

  mutations: {
    setUtm(state, utm) {
      state.utm = utm
    },
    setBot(state) {
      if (process.env.NODE_ENV == 'development') {
        state.bot_url = 'https://t.me/MyVPNdev_bot'
      }

    }

  },
  getters: {
    utm: state => state.utm,
    bot_url: state => state.bot_url
  }
});


if (navigator.userAgent.match(/iPhone/i)) {
  //window.location.href = 'https://apps.apple.com/it/app/myvpn/id6479427630'
}
// if(navigator.userAgent.match(/Android/i)){
//     window.location.href = 'https://play.google.com/store/apps/details?id=ai.myvpn.app'
// }
if (navigator.userAgent.match(/Android/i)) {
  const app = createApp(Android);
  app.mount("#app");
  // var link = document.createElement('a');
  // link.href = 'https://play.google.com/store/apps/details?id=ai.myvpn.app';
  // link.click();

  // if(confirm("Download app?")) {
  // window.location.href = 'https://play.google.com/store/apps/details?id=ai.myvpn.app'
  // window.location.href= "market://details?id=ai.myvpn.app";
  // }
} else {
  const app = createApp(App);
  app.config.globalProperties.utm_content = get()

  app.use(MotionPlugin);
  app.use(store);
  app.use(router);

  app.mount("#app");
}







function get() {
  const params = new URLSearchParams(window.location.search);
  return "utm_" + params.get('utm_content');
}
