import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from './layouts/MainLayout.vue';
import Pay from './components/Pay.vue';

const routes = [
	{
		path: '/',
		name: 'MainLayout',
		component: MainLayout
	},
	{
		path: '/pay',
		name: 'Pay',
		component: Pay
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth'
			};
		} else {
			return { top: 0 };
		}
	}
});

export default router;