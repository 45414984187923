<template>
	<div class="main" v-motion-fade>
		<header>
			<HeaderComponent />
		</header>

		<div class="content">
			<router-view></router-view>
		</div>

		<footer>
			<FooterComponent />
		</footer>
	</div>
</template>

<script>
import HeaderComponent from './components/common/HeaderComponent.vue';
import FooterComponent from './components/common/FooterComponent.vue';
import axios from 'axios';

export default {
	name: 'App',
	components: {
		HeaderComponent,
		FooterComponent
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		let params = {};

		for (const [key, value] of urlParams) {
			params[key] = value;
		}

		ym(95269774, 'getClientID', (clientID) => {
			params['yandexClientID'] = clientID;
			axios.post('https://myvpn.ai:3443/api/click', params).then(response => {

				params['utm_id'] = response.data.utm_id;
				localStorage.setItem('utm', JSON.stringify(params));
				this.$store.commit('setUtm', params.utm_id);
				this.$store.commit('setBot');



			}).catch(error => {
				console.log(error);
			});


		});



	}
}
</script>

<style></style>
