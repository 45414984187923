<template>
    <div class="container">
        <div class="header-container" id="head">
            <router-link class="title header-link" :to="{ path: '/'}" @click="closeBurger">My<span class="orange">VPN</span></router-link>
            <nav class="header-nav" :class="{ open: isBurgerOpen }">
                <ul class="nav-list">
                    <li class="list-item">
                        <router-link class="text-medium link" :to="{ path: '/', hash: '#why' }" @click="closeBurger">Почему MyVPN</router-link>
                    </li>
                    <li class="list-item">
                        <router-link class="text-medium link" :to="{ path: '/', hash: '#locations' }" @click="closeBurger">Локации</router-link>
                    </li>
                    <li class="list-item">
                        <router-link class="text-medium link" :to="{ path: '/', hash: '#tariff' }" @click="closeBurger">Тариф</router-link>
                    </li>
                    <li class="list-item">
                        <router-link class="text-medium link" :to="{ path: '/', hash: '#questions' }" @click="closeBurger">Вопросы</router-link>
                    </li>
                    <li class="list-item">
                        <router-link class="text-medium link" to="/pay" @click="closeBurger">Оплата</router-link>
                    </li>
                </ul>
                <button class="button orange-hover burger-button">
                    <a class="button-link" target="_blank" href="https://t.me/myvpn_chat">
                        Чат с поддержкой
                    </a>
                </button>
            </nav>
            <button class="button orange-hover header-button">
                <a class="button-link" target="_blank" href="https://t.me/myvpn_chat">
                    Чат с поддержкой
                </a>
            </button>
            <button class="burger" @click="toggleBurger">
                <span class="burger-line" :class="{ open: isBurgerOpen }"></span>
                <span class="burger-line" :class="{ open: isBurgerOpen }"></span>
                <span class="burger-line" :class="{ open: isBurgerOpen }"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderComponent',
    data() {
        return {
            isBurgerOpen: false,
        };
    },
    methods: {
        toggleBurger() {
            this.isBurgerOpen = !this.isBurgerOpen;
            if (this.isBurgerOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
        closeBurger() {
            this.isBurgerOpen = false;
            document.body.style.overflow = 'auto';
        },
    },
};
</script>

<style></style>
