<script>
export default {
        name: 'Why',
        computed: {
                utm_id() {
                        return this.$store.getters.utm
                },
                bot_url() {
                        return this.$store.getters.bot_url
                }
        }

}
</script>

<template>
        <div class="container">
                <div class="why-container text-center">
                        <h2 class="title mb-60 fs-56">Почему именно My<span class="orange">VPN</span></h2>
                        <ul class="why-list mb-60">
                                <li class="why-item br-24 pd-24 bg-grey div1">
                                        <img class="why-img" src="../assets/images/rocket.svg" alt=""><span
                                                class="text-semi text-light">Высокая скорость</span>
                                </li>
                                <li class="why-item br-24 pd-24 bg-white div2"><img
                                                class="why-img" src="../assets/images/eye-off.svg" alt=""><span
                                                class="text-semi text-grey">Без сбора данных</span></li>
                                <li class="why-item br-24 pd-24 bg-yellow div3"><img
                                                class="why-img" src="../assets/images/megaphone.svg" alt=""><span
                                                class="text-semi text-grey">Без рекламы</span></li>
                                <li class="why-item br-24 pd-24 bg-light div4"><img
                                                class="why-img" src="../assets/images/easy-reg.svg" alt=""><span
                                                class="text-semi text-grey">Без регистрации</span></li>
                                <li class="why-item br-24 pd-24 bg-ligth-yellow div5"><img
                                                class="why-img" src="../assets/images/one-click.svg" alt=""><span
                                                class="text-semi text-grey">Подключение в один клик</span></li>
                                <li class="why-item br-24 pd-24 bg-light div6"><img
                                                class="why-img" src="../assets/images/gift.svg" alt=""><span
                                                class="text-semi text-grey">Бесплатный период 3 дня</span></li>
                                <li class="why-item br-24 pd-24 bg-white div7"><img
                                                class="why-img" src="../assets/images/shield-check.svg" alt=""><span
                                                class="text-semi text-grey">Защищённый доступ к сети</span></li>
                                <li v-motion-fade-visible-once class="why-item br-24 pd-24 bg-grey div8"><img
                                                class="why-img" src="../assets/images/lock.svg" alt=""><span
                                                class="text-semi text-light">Устойчивость к взлому</span></li>
                                <li id="section1" v-motion-fade-visible-once class="why-item br-24 pd-24 bg-yellow div9"><img
                                                class="why-img" src="../assets/images/globe.svg" alt=""><span
                                                class="text-semi text-grey">Локации по всему миру</span></li>
                        </ul>
                        <button  class="button orange-bg fs-28 orange-hover">
                                Установить
                        </button>
                        <!-- <div  class="" style="margin-top: 20px;">
                                <ul class="min-bottom-list">
                                        <li class="min-item ">
                                                <a target="_blank"
                                                        href="https://apps.apple.com/it/app/myvpn/id6479427630">
                                                        <img src="../assets/images/appstore.png" alt="Apple">
                                                </a>
                                        </li>
                                        <li class="min-item "><a target="_blank"
                                                        href="https://play.google.com/store/apps/details?id=ai.myvpn.app"><img
                                                                src="../assets/images/googleplay.png" alt="Google"></a></li>
                                </ul>
                        </div> -->
                </div>
        </div>
</template>

<style scoped>
.app-bg {
        background-color: rgb(0, 0, 0);
}
.min-bottom-list {
    display: flex;
    justify-content: space-around;
  }
</style>